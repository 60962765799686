import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalInner } from 'components/Modal/Modal'
import { Row, Column, Text } from '@stokr/components-library'
import Button from 'components/Button/Button.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Input from 'components/Input/Input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormError, FormField } from 'components/Form/Form.styles'
import Form from 'components/Form/Form'
import {
  ContainerWithLine,
  RedBar,
} from 'components/InvestorsModal/InvestorsModal.styles'
import DatePickerInput from 'components/Input/DatePickerInput'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'

const NAVModal = ({
  isModalOpen,
  onModalClose,
  title,
  caption,
  addNAV,
  value,
  onSubmit,
  editNAV,
  errorMessage,
  isUpdatingNAV,
}) => {
  const { addNAVPrice, project, getNAVPrices } = useContext(ProjectContext)

  const [errorMessageModal, setErrorMessageModal] = useState('')
  const [successMessage, setsuccessMessage] = useState('')
  const [isUpdating, setisUpdating] = useState(false)

  const getValidationSchema = () => {
    if (addNAV) {
      return Yup.object().shape({
        date: Yup.date().required('Date is required'),
        navUnit: Yup.number().required(`Oops, this can't be blank`),
      })
    }
  }

  const handleAddNAV = async (data) => {
    setErrorMessageModal('')
    setsuccessMessage('')
    setisUpdating(true)

    let dataToSend = {
      assetId: project.secondaryAssetId,
      price: Number(data.navUnit),
      time: data.date,
    }

    try {
      await addNAVPrice(dataToSend)
      setsuccessMessage('You have successfully added a NAV price')

      await getNAVPrices({
        assetId: project.secondaryAssetId,
      })
      setTimeout(() => {
        onModalClose && onModalClose()
      }, 1500)
    } catch (error) {
      console.log('🚀 ~ handleUpdateNAV ~ error:', error)
      setErrorMessageModal('Oops, something went wrong. Please try again.')
    } finally {
      setisUpdating(false)
    }
    return {}
  }
  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} closeIsFullHeight>
      <Row>
        <Column relative part={11}>
          <ModalInner isConfirmModal>
            <ContainerWithLine>
              <Text withBorder>
                <h3>{title}</h3>
              </Text>

              <Formik
                initialValues={{
                  navUnit: value.navUnit,
                  date: value.date,
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleAddNAV}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  const onChangeWithTouch = (e) => {
                    const field = e.target
                    setFieldValue(field.name, field.value, false)
                    setFieldTouched(field.name)
                  }

                  const onDateChange = async (date) => {
                    const utcDate = new Date(
                      Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                      ),
                    )

                    await setFieldValue('date', utcDate.toISOString(), false)
                    await setFieldTouched('date', true, true)
                  }

                  const isButtonDisabled =
                    isUpdating || !values.date || !values.navUnit

                  return (
                    <Form onSubmit={onSubmit}>
                      <FormField>
                        <br />
                        {addNAV && (
                          <>
                            <Input
                              id="navUnit"
                              name="navUnit"
                              type="number"
                              label="add NAV per unit"
                              value={values.navUnit}
                              onChange={onChangeWithTouch}
                              onBlur={handleBlur}
                              error={!!errors.navUnit}
                              touched={!!touched.navUnit}
                            />
                            <FormError show={errors.navUnit && touched.navUnit}>
                              {errors.navUnit}
                            </FormError>
                            <br />
                            <DatePickerInput
                              // dashboard
                              label="Add Date"
                              id="NAV-date"
                              name="date"
                              placeholder="ADD DATE"
                              value={values.date}
                              weekStartsOn={1}
                              captionLayout="dropdown-buttons"
                              fixedWeeks
                              mode="single"
                              onChange={onDateChange}
                              //onBlur={handleBlur}
                            />

                            <FormError show={errors.date && touched.date}>
                              {errors.date}
                            </FormError>
                            <br />
                            <Text.Caption>{caption}</Text.Caption>
                          </>
                        )}
                      </FormField>
                      <FormError show={errorMessageModal}>
                        {errorMessageModal}
                      </FormError>
                      <Text success>
                        <p style={{ fontSize: 16 }}>{successMessage}</p>
                      </Text>
                      <br />

                      <ComponentWrapper noPaddingBottom>
                        <Button type="submit" disabled={isButtonDisabled}>
                          {isUpdating ? 'Processing..' : 'Submit'}
                        </Button>
                      </ComponentWrapper>
                    </Form>
                  )
                }}
              </Formik>
            </ContainerWithLine>
          </ModalInner>
        </Column>
      </Row>
      <RedBar />
    </Modal>
  )
}

NAVModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  caption: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.shape({
    email: PropTypes.string,
    status: PropTypes.string,
    notes: PropTypes.string,
  }),
  addNAV: PropTypes.bool,
  editNAV: PropTypes.bool,
  errorMessage: PropTypes.string,
}

NAVModal.defaultProps = {
  caption: '',
  title: '',
  value: {
    navUnit: '',
    date: '',
  },
  errorMessage: '',
}

export default NAVModal
