import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Wrap, Img, Content } from './Background.styles'

class Background extends PureComponent {
  render() {
    const { children, src, mobileRatio } = this.props

    return (
      <Container mobileRatio={mobileRatio}>
        <Wrap>
          <Img src={src} alt="background" />
        </Wrap>
        <Content>{children}</Content>
      </Container>
    )
  }
}

Background.propTypes = {
  src: PropTypes.string.isRequired,
  mobileRatio: PropTypes.string, // example: '16:9'
  children: PropTypes.node,
}

Background.defaultProps = {
  mobileRatio: '',
  children: <div />,
}

export default Background
