import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DayPicker, useInput } from 'react-day-picker'
import { Wrapper, Label } from './Input.styles'

import { DatePickerOverlay, DateInput } from './DatePickerInput.styles'
import { useComponentVisible } from 'utils/customHooks'

const DatePickerInput = (props) => {
  const {
    onChange,
    name,
    value,
    id,
    label,
    placeholder,
    error,
    touched,
    dashboard,
  } = props

  const [labelUp, setlabelUp] = useState(false)
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const { inputProps, dayPickerProps, setSelected } = useInput({
    //defaultSelected: new Date(),
    //fromYear: 2021,
    //toYear: 2023,
    format: 'PP',
    required: true,
  })

  useEffect(() => {
    checkLabel(false)
  }, [])

  const handleOnChange = (value) => {
    setSelected(value)
    onChange && onChange(value, { name, value })
  }

  const checkLabel = (focus) => {
    setlabelUp(focus || !!value)
  }

  return (
    <Wrapper ref={ref} onClick={() => setIsComponentVisible(true)}>
      {label && (
        <Label isUp={labelUp} error={error && touched} htmlFor={id}>
          {label}
        </Label>
      )}
      <DatePickerOverlay onClick={() => checkLabel(true)} dashboard={dashboard}>
        {/* <DayPicker
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onDayChange={handleOnChange}
          error={error}
        /> */}
        <DateInput
          {...inputProps}
          dashboard={dashboard}
          placeholder={placeholder}
        />
        {isComponentVisible && (
          <DayPicker
            {...dayPickerProps}
            {...props}
            onDayClick={handleOnChange}
          />
        )}
      </DatePickerOverlay>
    </Wrapper>
  )
}

DatePickerInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  dashboard: PropTypes.bool,
}

DatePickerInput.defaultProps = {
  label: '',
  placeholder: '',
  error: false,
  touched: false,
  dashboard: false,
}

export default DatePickerInput
