import { colors } from '@stokr/components-library/dist/styles/colors'
import {
  InvestorStatus,
  ProfessionalInvestorTypes,
  USInvestorAcreditationStatuses,
} from 'pages/admin-dashboard/private-investor'
import styled from 'styled-components/macro'
import grid from 'styles/grid'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;

  ${rwd.Medium`
    width:calc(100% + 100px);
    margin-left:-50px;
  `}

  ${rwd.Large`
    width:auto;
    margin-left:0;
  `}
`

export const Row = styled.div`
  background-color: ${(props) => props.theme.cPrimaryDark};
  color: ${(props) => props.theme.cWhite};
  padding: 22px 32px;
  margin: 0 -22px;

  ${rwd.Medium`
    margin:0 0px;
    display: flex;
    padding: 10px ;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `}

  ${rwd.Large`
    margin:0 -50px;
    padding: 22px ${grid(0.6)};
  `}



  ${(props) =>
    props.head &&
    `
    display: none;
    background-color: ${props.theme.cPrimary};
    color: #ffffff;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 2.06px;
    text-transform: uppercase;
    min-height: 56px;
    padding: 10px 32px;

    ${rwd.Large`
      display: flex;
      padding: 10px ${grid(1)};
    `}
    
  `}

  &:nth-child(n+3) {
    border-top: 1px solid #003e9d;
  }

  ${(props) =>
    props.borderBottom &&
    `
  border-bottom:1px solid #003e9d;`}
`

export const Row2 = styled.div`
  background-color: ${(props) => props.theme.cPrimaryDark};
  color: ${(props) => props.theme.cWhite};
  display: table-row;

  ${(props) =>
    props.head &&
    `
    background-color: ${props.theme.cPrimary};
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 2.06px;
    text-transform: uppercase;
  `}
`
export const Column2 = styled.div`
  display: table-cell;
  width: ${(props) => props.width || '30%'};
  padding: 10px;
  vertical-align: middle;

  ${(props) =>
    props.balanceText &&
    `
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  `}
  ${(props) =>
    props.icon &&
    `
    text-align:center;`}

     ${(props) =>
    props.nameBox &&
    `
    padding-left:32px;
  `}
`

export const StatusBox = styled.div`
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: 1.78px;
  background-color: ${(props) => props.theme.cWarning};
  border-radius: 10px;
  color: ${(props) => props.theme.cWhite};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  ${(props) =>
    (props.state === InvestorStatus.ADMITTED ||
      props.state === InvestorStatus.APPROVED) &&
    `
    background-color: ${props.theme.cLighterGreen}; 
    color:${props.theme.cBlack};
  `}
  ${(props) =>
    props.state === InvestorStatus.NOT_ADMITTED &&
    `
    background-color: ${props.theme.cBlack};
  `}
   ${(props) =>
    props.state === InvestorStatus.CONTACTED &&
    `
    background-color: ${props.theme.cGrey2};
  `}
`

export const Column = styled.div`
  ${(props) => props.marginTop && ` margin-top:20px; `}

  ${(props) =>
    props.clickable &&
    `
    cursor:pointer;
  `}

  ${(props) =>
    props.balanceText &&
    `
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  `}

${rwd.Medium`
  ${(props) => props.balanceText && ` font-size: 16px; `}

  ${(props) =>
    props.nameBox &&
    `
    max-width:${props.width || '30%'};
    flex-direction:column;
  `}
`}


  ${rwd.Large`
    display: inline-block;
    padding-right: 10px;
    margin-top:0;
    width:${(props) => props.width || '30%'};

  ${(props) =>
    props.nameBox &&
    `
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    `}

  ${(props) =>
    props.icon &&
    `
  display:flex;
  justify-content:space-around;
  align-items:center;
  padding-right:0;`}

  `}
  
  ${(props) =>
    props.icon &&
    `
  display:flex;
  justify-content:space-around;
  align-items:center;
  padding-right:0;`}

  ${(props) =>
    props.nameBox &&
    `
      display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `}
`

export const CircleWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
`

export const HalfCircle = styled.div`
  width: 100%;
  height: 50%; /* Half the height of the full circle */
  position: absolute;
  ${(props) =>
    props.side === 'top' &&
    `
    top: 0;
    border-bottom: 0.5px solid ${colors.blueDark}; 
  `}
  ${(props) =>
    props.side === 'bottom' &&
    `
    bottom: 0;
    border-top: 0.5px solid ${colors.blueDark}; 
  `}
  left:0;
`

export const ProfessionalInvCircle = styled.div`
  width: 100%;
  height: 100%;

  ${(props) =>
    props.type === ProfessionalInvestorTypes.PROFESSIONAL &&
    `
    background-color: ${props.theme.cWhite};
    `}
  ${(props) =>
    props.type === ProfessionalInvestorTypes.NOT_PROFESSIONAL &&
    `
    background-color: ${props.theme.cBlack};
    `}
${(props) =>
    props.type === ProfessionalInvestorTypes.NOT_CLASSIFIED &&
    `
    background-color: ${props.theme.cPrimaryDark};
    //box-shadow: none;
    `}
`

export const USInvestorCircle = styled.div`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.type === USInvestorAcreditationStatuses.US_ACCREDITED &&
    `
      background-color: ${props.theme.cWhite};
    `}
  ${(props) =>
    props.type === USInvestorAcreditationStatuses.NOT_US_ACCREDITED &&
    `
      background-color: ${props.theme.cBlack};
    `}
  ${(props) =>
    props.type === USInvestorAcreditationStatuses.NOT_CLASIFIED &&
    `
      background-color: ${props.theme.cPrimaryDark};
    `}
`

export const Avatar = styled.div`
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.cPrimary};
  overflow: hidden;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
`

export const AvatarImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const Name = styled.div`
  ${rwd.Medium`
  margin-left: 0px;
`}

  ${rwd.Large`
  margin-left: 16px;

  ${(props) =>
    props.noMargin &&
    `
  margin:0;
  `}
  
  `}

  display: inline-block;
  margin-left: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  word-break: break-all;

  ${(props) =>
    props.small &&
    `
  font-size:14px;
  `}

  ${(props) =>
    props.noMargin &&
    `
  margin:0;
  `}
`
