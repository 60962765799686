import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { fixDecimals } from 'utils'
import StokrLoader from 'components/StokrLoader/StokrLoader'
import countriesList from 'utils/countries-list'
import Project from './Project'
import { getCurrencySymbol } from 'utils/getCurrencySymbol'

export const ProjectContext = React.createContext()

const NOT_AVAILABLE = 'Not available'

export class ProjectProvider extends Component {
  state = {
    isLoading: true,
    isLoadingInvestorRegister: true,
    project: undefined,
    user: undefined,
    numberOfInvestors: '-',
    daysLeft: '-',
    daysLeftPercentage: undefined,
    capitalRaised: 0,
    privateSale: 0,
    hardCap: 0,
    softCap: 0,
    softCapPercentage: 0,
    privateSalePercentage: 0,
    publicSalePercentage: 0,
    allSaleStatus: {},
    currentSaleStatus: '-',
    investmentRatio: {},
    investmentsList: [],
    investorRegister: [],
    secondaryMarketTx: [],
    redemptionTx: [],
    investorRegisterError: null,
    mapData: undefined,
    tokensSoldOverTime: undefined,
    tokenSymbol: '',
    tokenPrice: 0,
    privateInvestors: [],
  }

  async componentDidMount() {
    const { user } = this.props
    if (user) {
      const project = await Project.getProject(user)
      this.setState({ project }, () => {
        this.setProjectMetadata()
        this.setPrivateInvestors()
      })
    }
  }

  parseBlockContent = (blockContent) => {
    const parsedContent = {}

    if (blockContent) {
      blockContent.forEach((item) => {
        const indexOfUnderscore = item.label.indexOf('_')
        const withoutBlockName = item.label.substring(indexOfUnderscore + 1)

        parsedContent[withoutBlockName] = {
          type: item.type,
          body: item.body,
          uri: item.uri,
        }
      })
    }

    return parsedContent
  }

  // TODO: Get currencies from Back Office
  getInvestmentRatio = (investments) => {
    const investedAmount = {}
    let totalTokens = 0

    // Only investments that are finalized
    investments
      .filter(
        (investment) =>
          investment.fulfilled || investment.currencyType === 'ether',
      )
      .forEach((investment) => {
        const { currencyType, tokenAmount, isRedemption } = investment

        //ignore redemptions when calculating the ratio
        if (isRedemption) return

        //ignore if currency type is  missing
        if (!currencyType) return

        const intTokenAmount = parseInt(tokenAmount)
        totalTokens += intTokenAmount

        if (investedAmount[currencyType]) {
          investedAmount[currencyType] += intTokenAmount
        } else {
          investedAmount[currencyType] = intTokenAmount
        }
      })

    if (totalTokens > 0) {
      for (const [currency, amount] of Object.entries(investedAmount)) {
        investedAmount[currency] = fixDecimals((amount / totalTokens) * 100, 2)
      }
    }
    return investedAmount
  }

  getMonthsValues = (daysNumber) => {
    var days = []
    for (let index = 1; index <= daysNumber; index++) {
      if (index < 10) {
        days.push({ name: `0${index}`, value: 0 })
      } else days.push({ name: `${index}`, value: 0 })
    }
    return days
  }

  getYearMonths = (year, startingDate = null) => {
    var months = []
    var length = 12
    var startingIndex = 0
    if (Number(year) === moment().year()) {
      length = moment().format('MM')
    }
    if (startingDate && Number(year) === moment(startingDate).year()) {
      startingIndex = Number(moment(startingDate).format('MM')) - 1
    }
    for (let index = startingIndex; index < length; index++) {
      months.push({
        name: moment().month(index).format('MMM'),
        value: 0,
      })
    }
    return months
  }

  getTokensSoldOverTime = (investments, project) => {
    const investmentsByMonthObj = {}
    const investmentByYearArray = []
    const investmentByWeekObj = {}
    const investmentByAllTime = []
    var addedDefaultValuesMonths = false,
      addedDefaultValuesWeek = false

    investments
      .filter(
        (investment) =>
          investment.fulfilled || investment.currencyType === 'ether',
      )
      .forEach((investment) => {
        const { createdAt, tokenAmount } = investment
        const intTokenAmount = parseInt(tokenAmount)

        const day = moment(createdAt).format('DD')
        const month = moment(createdAt).format('MMMM')
        const year = moment(createdAt).format('YYYY')
        const startOfWeek = moment(createdAt).startOf('isoWeek')
        const endOfWeek = moment(createdAt).endOf('isoWeek')
        const daysInMonth = moment(createdAt).daysInMonth()

        //month === 'January' && console.log('🚀 ~ investment', investment)
        const slicedStartOfWeek = startOfWeek.toString().slice(4, 15)
        const slicedEndOfWeek = endOfWeek.toString().slice(4, 15)
        const dayName = moment(createdAt).format('ddd')

        const weekTitle = `${slicedStartOfWeek} - ${slicedEndOfWeek}`
        const title = `${month} ${year}`

        //add to array only if the object (year) doesn't exist in the array
        let index = investmentByYearArray.findIndex((x) => x.title === year)
        index === -1 &&
          investmentByYearArray.push({
            title: year,
            values: this.getYearMonths(year, project.startingDate),
          })

        const monthDefaultValues = this.getMonthsValues(daysInMonth)

        //For month Data
        if (investmentsByMonthObj[title] && investmentsByMonthObj[title][day]) {
          // Month and day are present
          investmentsByMonthObj[title] = {
            ...investmentsByMonthObj[title],
            [day]: investmentsByMonthObj[title][day] + intTokenAmount,
          }
        } else if (investmentsByMonthObj[title]) {
          //Month is present but day isn't
          investmentsByMonthObj[title] = {
            ...investmentsByMonthObj[title],
            [day]: intTokenAmount,
          }
        } else {
          // Month and day are  NOT present or just month is present
          //First time add the dafult values for month(all days have value 0)
          addedDefaultValuesMonths = false

          if (!addedDefaultValuesMonths) {
            monthDefaultValues.forEach((element) => {
              investmentsByMonthObj[title] = {
                ...investmentsByMonthObj[title],
                [element.name]: element.value,
              }
            })
            addedDefaultValuesMonths = true
          }

          investmentsByMonthObj[title] = {
            ...investmentsByMonthObj[title],
            [day]: intTokenAmount,
          }
        }

        //For week data --same logic as above

        if (
          investmentByWeekObj[weekTitle] &&
          investmentByWeekObj[weekTitle][dayName]
        ) {
          // Week and day are present
          investmentByWeekObj[weekTitle] = {
            ...investmentByWeekObj[weekTitle],
            [dayName]: investmentByWeekObj[weekTitle][dayName] + intTokenAmount,
          }
        } else if (investmentByWeekObj[weekTitle]) {
          //Week is present but day isn't
          investmentByWeekObj[weekTitle] = {
            ...investmentByWeekObj[weekTitle],

            [dayName]: intTokenAmount,
          }
        } else {
          // Week and day are both NOT present or just week is present

          //First time add the dafult values for week(all days have value 0)
          addedDefaultValuesWeek = false
          if (!addedDefaultValuesWeek) {
            investmentByWeekObj[weekTitle] = {
              ...investmentByWeekObj[weekTitle],
              Mon: 0,
              Tue: 0,
              Wed: 0,
              Thu: 0,
              Fri: 0,
              Sat: 0,
              Sun: 0,
            }
            addedDefaultValuesWeek = true
          }
          investmentByWeekObj[weekTitle] = {
            ...investmentByWeekObj[weekTitle],

            [dayName]: intTokenAmount,
          }
        }
      })

    const investmentsByMonthArray = Object.entries(investmentsByMonthObj).map(
      (month) => {
        const [title, values] = month
        let sumTotal = 0
        //var valuesArray = monthDefaultValues

        const valuesArray = Object.entries(values).map((value) => {
          const [day, totalSold] = value
          sumTotal += totalSold
          return {
            name: day,
            value: totalSold,
          }
        })

        //sort to show the dates correctly
        valuesArray.sort((a, b) => Number(a.name) - Number(b.name))

        return {
          title,

          values: valuesArray,
          total: sumTotal,
        }
      },
    )

    const investmentByWeekArray = Object.entries(investmentByWeekObj).map(
      (week) => {
        const [title, values] = week

        const valuesArray = Object.entries(values).map((value) => {
          const [day, totalSold] = value
          return {
            name: day,
            value: totalSold,
          }
        })

        return {
          title,

          values: valuesArray,
        }
      },
    )

    investmentByYearArray.forEach((year) => {
      var valuesArray = year.values

      investmentsByMonthArray.forEach((month) => {
        const monthName = month.title.split(' ')[0]
        const monthYear = month.title.split(' ')[1]

        const monthsShort = monthName.slice(0, 3)

        if (year.title === monthYear) {
          // valuesArray.push({ name: monthsShort, value: month.total })
          let index = valuesArray.findIndex((x) => x.name === monthsShort)
          valuesArray[index] = { name: monthsShort, value: month.total }
        }
      })
      year.values = valuesArray
    })

    var valuesArray = []

    investmentByYearArray.forEach((year) => {
      year.values.forEach((value) => {
        valuesArray.push({
          name: `${value.name} ${year.title}`,
          value: value.value,
        })
        //valuesArray.push(value)
      })
    })
    investmentByAllTime.push({
      title: 'all time',
      values: valuesArray,
    })

    return {
      years: investmentByYearArray,
      months: investmentsByMonthArray,
      weeks: investmentByWeekArray,
      days: [],
      max: investmentByAllTime,
    }
  }

  patchTokenHolders = (tokenHolders, project) => {
    const holdersArray = tokenHolders
      .sort((a, b) => (a.balance < b.balance ? 1 : -1))
      .map((holder) => {
        const { balance, country, email, taxId } = holder

        holder = {
          ...holder,
          email: email || 'No email',
          balance: parseFloat(
            fixDecimals(balance, project.tokenDecimals),
          ).toLocaleString('en', {
            minimumFractionDigits: project.tokenDecimals,
          }),
          country: country || NOT_AVAILABLE,
          countryAbbreviation:
            country &&
            countriesList
              .find(({ name }) => name === country)
              .ISO2.toLowerCase(),
          taxId: taxId || 'No Tax ID',
        }
        return holder
      })

    return holdersArray
  }

  createMapData = (tokenHolders, project) => {
    const getCategory = (investors) => {
      if (investors <= 10) return 'from1to10'
      if (investors <= 50) return 'from10to50'
      if (investors > 50) return 'from50'
    }

    const countriesListFinal = {}

    tokenHolders.forEach((holder) => {
      const { country, balance } = holder

      // Skip if user has no country or it was not found
      if (country !== NOT_AVAILABLE) {
        const countryAbbreviation = countriesList.find(
          ({ name }) => name === country,
        ).ISO3

        if (countriesListFinal[countryAbbreviation]) {
          countriesListFinal[countryAbbreviation].investors++
          countriesListFinal[countryAbbreviation].value += parseFloat(
            balance * project.tokenPrice,
          )
          countriesListFinal[countryAbbreviation].category = getCategory(
            countriesListFinal[countryAbbreviation].investors,
          )
        } else {
          countriesListFinal[countryAbbreviation] = {
            investors: 1,
            value: parseFloat(balance * project.tokenPrice),
            category: getCategory(1),
            currencySymbol: getCurrencySymbol(project.tokenCurrency),
          }
        }
      }
    })

    return countriesListFinal
  }

  setProjectMetadata = async () => {
    const { project } = this.state

    // Days left
    const oneDay = 1000 * 60 * 60 * 24
    const now = new Date()
    const startingDate = new Date(project.openingTime)
    const endingDate = new Date(project.closingTime ? project.closingTime : '')
    let daysLeft = Math.round(Math.abs((now - endingDate) / oneDay))
    if (!daysLeft || isNaN(daysLeft)) {
      daysLeft = '-'
    }

    const offeringPeriod = Math.round(
      Math.abs((endingDate - startingDate) / oneDay),
    )
    const daysLeftPercentage = parseInt(
      ((offeringPeriod - daysLeft) / offeringPeriod) * 100,
    )

    let hardCap = Number(project.whiteCap) + Number(project.greenCap)

    // Progress bar percentages
    const softCapPercentage = (project.totalSoftCap / hardCap) * 100
    const publicSalePercentage = (project.greenSold / project.greenCap) * 100
    const privateSalePercentage = (project.whiteSold / project.whiteCap) * 100

    // Status
    const currentSaleStatus = project.isFinalized
      ? 'Contract finalized'
      : project.hasClosed
      ? 'Offering period ended'
      : project.isOpen
      ? 'Offering period started'
      : 'Deployed'

    //set project data into state first
    this.setState({
      daysLeft: daysLeft,
      publicSale: project.greenSold,
      daysLeftPercentage,
      privateSale: project.whiteSold,
      capitalRaised: project.totalSold,
      hardCap,
      softCap: project.total,
      softCapPercentage,
      privateSalePercentage,
      publicSalePercentage,
      currentSaleStatus,
      tokenSymbol: project.tokenSymbol,
      tokenPrice: project.tokenPrice,
    })

    //-----INVESTMENTS
    try {
      // Investment table
      const investmentsList = await Project.getInvestmentList()
      const investmentRatio = this.getInvestmentRatio(investmentsList)
      const tokensSoldOverTime = this.getTokensSoldOverTime(
        investmentsList,
        project,
      )
      const redemptionTx = getRedemptionTx(investmentsList)

      //set investments
      this.setState({
        investmentsList,
        investmentRatio,
        tokensSoldOverTime,
        redemptionTx,
      })
    } catch (error) {
      console.log('🚀 ~ error in investments', error)
    }

    // NAV PRICE
    try {
      if (project.name === 'aquarius') {
        await this.getNAVPrices({
          assetId: project.secondaryAssetId,
        })
      }
    } catch (error) {
      console.log('🚀 ~ error in getting nav price:', error)
    }

    // Render the dashboard with all necessary data
    //don't wait for investor Register EP
    this.setState({ isLoading: false })

    //----TOKEN DATA---INVESTOR REGISTER
    try {
      if (project.connectedProjects?.length > 0) {
        const connectedProjects = await Project.getProjectsByName(
          project.connectedProjects,
        )
        project.connectedProjects = connectedProjects
      }
      const investorRegister = await Project.getInvestorRegister(project)

      const finalInvestorRegister = this.patchTokenHolders(
        investorRegister,
        project,
      )

      const secondaryMarketTx = getSecondaryMarketTx(finalInvestorRegister)

      const mapData = this.createMapData(finalInvestorRegister, project)

      this.setState({
        investorRegister: finalInvestorRegister,
        secondaryMarketTx,
        mapData,
      })
    } catch (error) {
      console.log('🚀 ~ error in token data', error)
      this.setState({
        investorRegisterError: (
          <>
            Something went wrong. Please contact{' '}
            <a
              href="mailto:support@stokr.io"
              style={{ color: 'inherit', fontWeight: 300 }}
            >
              support@stokr.io
            </a>{' '}
          </>
        ),
      })
    }
    this.setState({ isLoadingInvestorRegister: false })
  }

  setPrivateInvestors = async () => {
    const { project } = this.state

    const privateInvestors = await Project.getPrivateInvestors()

    //add tax id field to private investor to check whether to show tax id field
    privateInvestors.forEach((element) => {
      element.requiresTaxId = project.requiresTaxId
    })

    this.setState({ privateInvestors })
  }

  createPrivateInvestor = async (data) => {
    if (await Project.createPrivateInvestor(data)) {
      this.setPrivateInvestors()
      return true
    }
    return false
  }

  updatePrivateInvestor = async (data) => {
    try {
      const response = await Project.updatePrivateInvestor(data)
      return response
    } catch (error) {
      throw error
    }
  }

  addNAVPrice = async (data) => {
    try {
      const response = await Project.addNAVPrice(data)
      return response
    } catch (error) {
      throw error
    }
  }

  getNAVPrices = async (data) => {
    try {
      const response = await Project.getNAVPrices(data)

      let groupedNAV = response
        ?.sort((a, b) => new Date(b.time) - new Date(a.time))
        .map((data) => {
          return {
            ...data,
            price: data.price.toFixed(2),
            date: moment(data.time).format('DD MMM YYYY'),
          }
        })
      this.setState({
        NAVPrices: groupedNAV,
      })
      return groupedNAV
    } catch (error) {
      throw error
    }
  }

  deleteNAVPrice = async (priceId) => {
    const { NAVPrices } = this.state
    try {
      const response = await Project.deleteNAVPrice({ priceId })

      let filteredNAVprices = NAVPrices?.filter((x) => x._id !== priceId)

      this.setState({
        NAVPrices: filteredNAVprices,
      })

      return response
    } catch (error) {
      console.log('🚀 ~ deleteNAVPrice ~ error:', error)
      throw error
    }
  }

  render() {
    const { children } = this.props
    const { isLoading } = this.state

    return (
      <ProjectContext.Provider
        value={{
          ...this.state,
          setProjectContextState: (state) => this.setState(state),
          setPrivateInvestors: this.setPrivateInvestors,
          createPrivateInvestor: this.createPrivateInvestor,
          updatePrivateInvestor: this.updatePrivateInvestor,
          patchTokenHolders: this.patchTokenHolders,
          addNAVPrice: this.addNAVPrice,
          getNAVPrices: this.getNAVPrices,
          deleteNAVPrice: this.deleteNAVPrice,
        }}
      >
        {isLoading ? <StokrLoader key="loader" /> : children}
      </ProjectContext.Provider>
    )
  }
}

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ProjectConsumer = ProjectContext.Consumer

const getSecondaryMarketTx = (investorRegister) => {
  let secondaryMarketTx = []

  investorRegister.forEach((holder) => {
    holder?.txData.forEach((tx) => {
      if (tx.type === 'Transfer' && !tx.investment) {
        secondaryMarketTx.push({
          ...tx,
          createdAt: moment(tx.time)
            .format('DD MMM YYYY - hh:mm')
            .toUpperCase(),
          recipientEmail: holder.wallets.some(
            (wallet) => wallet.address === tx.recipient,
          )
            ? holder.email
            : investorRegister.find((holderr) =>
                holderr.wallets.some(
                  (wallet) => wallet.address === tx.recipient,
                ),
              )?.email,
          senderEmail: holder.wallets.some(
            (wallet) => wallet.address === tx.sender,
          )
            ? holder.email
            : investorRegister.find((holderr) =>
                holderr.wallets.some((wallet) => wallet.address === tx.sender),
              )?.email,
        })
      }
    })
  })
  return secondaryMarketTx
}

const getRedemptionTx = (investments) => {
  return investments
    ?.filter((investment) => investment.isRedemption)
    .sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })
    .map((investment) => {
      return {
        ...investment,
        status: investment.paidOut
          ? 'PAID'
          : investment.fulfilled
          ? 'TO BE PAID'
          : 'REQUESTED',
      }
    })
}
