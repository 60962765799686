import React, { useState, useEffect, useContext, useMemo } from 'react'
import Layout from 'components/AdminDashboard/Layout/Layout'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import {
  Card,
  CardHeader,
  CardTitle,
} from 'components/AdminDashboard/Card/Card.styles'
import Button from 'components/Button/Button.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Text from 'components/Text/Text.styles'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'

import {
  DataManagementCounter,
  FlexCenterContainer,
} from 'components/Settings/Settings.styles'
import InvestorsTable from 'components/InvestorsTable/InvestorsTable'
import getProfilePicture from 'utils/getProfilePicture'
import { iconsMap } from 'components/Icon/Icon.style'
import InvestorsModal from 'components/InvestorsModal/InvestorsModal'
import Pagination from 'components/Pagination/Pagination'
import InputWithButton from 'components/AdminDashboard/InputWithButton/InputWithButton'
import sortListBy from 'utils/sort-by'
import { AuthContext } from '@stokr/components-library'
import parse from 'html-react-parser'
import CheckboxClass from 'context/CheckboxContext/Checkbox'

export const InvestorState = {
  DONE: 'done',
  NOT_DONE: 'not_done',
  IN_PROGRESS: 'in_progress',
  DENIED: 'denied',
}

export const InvestorStatus = {
  NEW: 'New',
  CONTACTED: 'Contacted',
  ADMITTED: 'Admitted',
  APPROVED: 'Approved',
  NOT_ADMITTED: 'Not admitted',
}

export const orderStates = {
  ASC: 'ASCENDING',
  DESC: 'DESCENDING',
}

export const InvestorStatusIcons = {
  [InvestorState.DONE]: iconsMap.check,
  [InvestorState.NOT_DONE]: iconsMap.warning,
  [InvestorState.IN_PROGRESS]: iconsMap.inProgress,
  [InvestorState.DENIED]: iconsMap.warning,
}

export const ProfessionalInvestorTypes = {
  PROFESSIONAL: 'professional',
  NOT_PROFESSIONAL: 'not professional',
  NOT_CLASSIFIED: 'not classified',
}

export const USInvestorAcreditationStatuses = {
  US_ACCREDITED: 'US accredited',
  NOT_US_ACCREDITED: 'not US accredited',
  NOT_CLASIFIED: 'not classified',
}

function AdminPrivateInvestor() {
  const {
    privateInvestors,
    project,
    setProjectContextState,
    createPrivateInvestor,
    updatePrivateInvestor,
  } = useContext(ProjectContext)

  const { user } = useContext(AuthContext)

  //investor states
  const [currentInvestor, setcurrentInvestor] = useState({})
  const [dataCounter, setdataCounter] = useState(0)

  //modal states
  const [editInvestorModal, seteditInvestorModal] = useState(false)
  const [newInvestorModal, setnewInvestorModal] = useState(false)
  const [errorMsg, seterrorMsg] = useState('')
  const [isUpdatingInvestor, setisUpdatingInvestor] = useState(false)

  //pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setpageSize] = useState(10)
  const [inputPageSize, setinputPageSize] = useState(pageSize.toString())

  //ordering states
  const [lastSortedBy, setLastSortBy] = useState('')
  const [sortedList, setSortedList] = useState([])

  //filtering states
  const [inputEmail, setinputEmail] = useState('')
  const [notFilteredList, setnotFilteredList] = useState([])

  //checkboxes
  const [checkboxes, setcheckboxes] = useState()

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return sortedList.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, sortedList, pageSize])

  useEffect(() => {
    fetchCheckboxes()
  }, [])

  useEffect(() => {
    addMissingFields(privateInvestors)
    countData(privateInvestors)
    setnotFilteredList([])
  }, [privateInvestors])

  useEffect(() => {
    if (errorMsg) {
      seterrorMsg('')
    }
  }, [editInvestorModal, newInvestorModal])

  const fetchCheckboxes = async () => {
    try {
      const response = await CheckboxClass.getCheckboxes({
        labels: ['addPrivateInvestor', 'addAcreditedInvestor'],
      })
      if (response != null) {
        setcheckboxes(response)
      }
    } catch (error) {
      console.log('🚀 ~ file: private-investor.js:118 ~ error:', error)
    }
  }

  const addMissingFields = async (list) => {
    if (list && list.length > 0) {
      let listWithAvatar = await Promise.all(
        list.map(async (item, index) => {
          //item.avatar = await getProfilePicture(item.user_id)
          item.onEditClick = (investor) => {
            setcurrentInvestor(investor)
            seteditInvestorModal(true)
          }

          //states are used for displaying icons in investor details (dropdown that opens)
          item.states = {
            account: item.user_id ? InvestorState.DONE : InvestorState.NOT_DONE,
            kyc:
              item.kyc_status === 'Accepted'
                ? InvestorState.DONE
                : !item.kyc_status
                ? InvestorState.NOT_DONE
                : item.kyc_status === 'Reapply' ||
                  item.kyc_status === 'Canceled' ||
                  item.kyc_status === 'Denied' ||
                  item.kyc_status === 'Escalated'
                ? InvestorState.DENIED
                : InvestorState.IN_PROGRESS,
            risk_quest: item.is_risk_questionnaire_finished
              ? InvestorState.DONE
              : InvestorState.NOT_DONE,
            wallet:
              item.wallets && item.wallets.length > 0
                ? InvestorState.DONE
                : InvestorState.NOT_DONE,
            taxId: item.taxId ? InvestorState.DONE : InvestorState.NOT_DONE,
          }

          //for user that don't have creation date - set the date to today(from midnight)
          if (!item.createdAt) {
            let today = new Date()
            today.setHours(0, 0, 0, 0)
            item.createdAt = today.toDateString()
          }

          if (!item.name) {
            if (item.username) {
              item.name = item.username
            } else {
              item.name = 'No name'
            }
          }

          //when the status is Approved, we show Admitted on the FE
          if (item.status === InvestorStatus.APPROVED) {
            item.status = InvestorStatus.ADMITTED
          }

          return item
        }),
      )
      //filter investor with email to prevent sorting issues
      listWithAvatar = listWithAvatar.filter((x) => x.email)

      //after updating investor we want to keep same page, order and sortBy
      sort(
        lastSortedBy || 'createdAt',
        orderStates.DESC,
        listWithAvatar,
        true,
        true,
      )
    }
  }

  const handleNewInvestor = async (values) => {
    //check if the user is already in the list
    const existAlready = sortedList.find((a) => a.email === values.email)
    if (existAlready) {
      seterrorMsg('User is already private investor.')
      return
    }

    try {
      if (
        await createPrivateInvestor({
          email: values.email,
          projectId: project._id,
          name: values.name,
          createPrivateInvestor: true,
          //if you click submit the checkbox is automatically checked
          checkedCheckboxes: [
            user?.user_type === 'company_pa'
              ? 'addAcreditedInvestor'
              : 'addPrivateInvestor',
          ],
        })
      ) {
        setnewInvestorModal(false)
        seterrorMsg('')
      }
    } catch (error) {
      console.log('🚀 ~ error', error)

      const errMessage = error?.response?.data
      const parsedErrMessage = errMessage?.substring(
        errMessage.indexOf(':') + 2,
      )
      if (parsedErrMessage) {
        seterrorMsg(parsedErrMessage)
      }
    }
  }

  const handleEditInvestor = async (values) => {
    setisUpdatingInvestor(true)

    //When VD admin sets state as Admitted, we send state Approved to BE
    if (values?.status === InvestorStatus.ADMITTED) {
      values.status = InvestorStatus.APPROVED
    }

    let data = {
      projectId: project._id,
      status: values.status,
      notes: values.notes,
    }

    //if the investor has stokr account we send the userId
    //else we store email in database
    if (currentInvestor.user_id) {
      data.userId = currentInvestor.user_id
    } else {
      data.email = values.email
    }

    //if you change the status to admitter, only then you check the checkbox
    if (InvestorStatus.ADMITTED === values.status) {
      data.checkedCheckboxes = [
        user?.user_type === 'company_pa'
          ? 'addAcreditedInvestor'
          : 'addPrivateInvestor',
      ]
    }

    try {
      const response = await updatePrivateInvestor(data)

      if (response.status === 200) {
        const updatedInvestor = {
          ...currentInvestor,
          status: data.status,
          notes: data.notes,
        }

        const newPrivateInvestorListWithUpdatedInvestor = privateInvestors.map(
          (item) => {
            if (item.user_id === updatedInvestor.user_id) {
              return updatedInvestor
            } else return item
          },
        )

        setProjectContextState((prevState) => ({
          ...prevState,
          privateInvestors: newPrivateInvestorListWithUpdatedInvestor,
        }))

        seteditInvestorModal(false)
        seterrorMsg('')
      } else {
        console.error(
          `Update investor failed with status: ${response.status}. If you see this, the call didn't throw error`,
        )
      }
    } catch (error) {
      console.log('🚀 ~ error', error.response)

      const errMessage = error?.response?.data
      const parsedErrMessage = errMessage?.substring(
        errMessage.indexOf(':') + 2,
      )
      if (parsedErrMessage) {
        seterrorMsg(parsedErrMessage)
      } else {
        seterrorMsg(
          'Something went wrong while updating private investor status',
        )
      }
    } finally {
      setisUpdatingInvestor(false)
    }
  }

  const countData = (list) => {
    let sum = list.reduce((sum, item) => {
      if (item.status === InvestorStatus.NEW) {
        sum += 1
      }
      return sum
    }, 0)
    setdataCounter(sum)
  }

  const sort = (
    sortBy,
    order = orderStates.ASC,
    list = sortedList,
    forceUpdate = false,
    keepSamePage = false,
  ) => {
    if (lastSortedBy === sortBy && !forceUpdate) {
      const reversed = [...sortedList]
      setSortedList(reversed.reverse())
    } else {
      const sorted = sortListBy(sortBy, list, order)
      setSortedList(sorted)
      setLastSortBy(sortBy)
    }
    //when sorting happens, always go to first page of the list
    if (!keepSamePage) {
      setCurrentPage(1)
    }
  }

  const handleFiltering = () => {
    //we need to keep track of the whole (unfiltered) list
    //so we can display original list if no filter applied

    const listToFilter =
      notFilteredList.length === 0 ? sortedList : notFilteredList

    if (inputEmail) {
      const filteredList = listToFilter.filter((a) =>
        a.email.includes(inputEmail),
      )

      if (notFilteredList.length === 0) setnotFilteredList(sortedList)

      setSortedList(filteredList)
    } else {
      setSortedList(notFilteredList)
    }
  }

  return (
    <Layout currentPage="private-investor">
      <Row mainDashboard>
        <Column>
          <ContentBox>
            <Row>
              <Column>
                <Card>
                  <CardHeader>
                    <Text withMarginBottom withMarginRight>
                      <h3>Private Investors</h3>
                    </Text>
                  </CardHeader>

                  <FlexCenterContainer
                    noPaddingHorizontal
                    style={{ padding: '16px' }}
                  >
                    <Text>
                      <h5>Data management</h5>
                    </Text>
                    {dataCounter > 0 && (
                      <DataManagementCounter>
                        {dataCounter}
                      </DataManagementCounter>
                    )}
                  </FlexCenterContainer>

                  <Text>
                    <p>From here you can manage all private investors</p>
                  </Text>

                  <ComponentWrapper noPaddingHorizontal>
                    <Button onClick={() => setnewInvestorModal(true)}>
                      Add new investor
                    </Button>
                  </ComponentWrapper>

                  <ComponentWrapper noPaddingHorizontal>
                    <Card noPadding>
                      <CardTitle style={{ padding: '2px 20px' }}>
                        Search by email:
                      </CardTitle>
                      <InputWithButton
                        id="filterby"
                        name="filterby"
                        value={inputEmail}
                        type="email"
                        placeholder={'Enter email'}
                        onChange={(e) => setinputEmail(e.target.value)}
                      >
                        <Button onClick={handleFiltering}>Search</Button>
                      </InputWithButton>
                    </Card>
                  </ComponentWrapper>

                  <InvestorsTable data={currentTableData} sort={sort} />
                  <ComponentWrapper
                    noPaddingHorizontal
                    flex
                    style={{
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Pagination
                      currentPage={currentPage}
                      totalCount={sortedList.length}
                      pageSize={pageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />

                    <Card noPadding>
                      <CardTitle style={{ padding: '2px 20px' }}>
                        Page Size:
                      </CardTitle>
                      <InputWithButton
                        id="pageSize"
                        name="pageSize"
                        value={inputPageSize}
                        type="number"
                        placeholder={'Page size'}
                        onChange={(e) => setinputPageSize(e.target.value)}
                      >
                        <Button
                          onClick={() => setpageSize(Number(inputPageSize))}
                        >
                          apply
                        </Button>
                      </InputWithButton>
                    </Card>
                  </ComponentWrapper>
                </Card>
              </Column>
            </Row>
          </ContentBox>
        </Column>
      </Row>

      {newInvestorModal && (
        <InvestorsModal
          isModalOpen={newInvestorModal}
          onModalClose={() => setnewInvestorModal(false)}
          title="Add Private Investor"
          caption={
            user?.user_type === 'company_pa'
              ? parse(
                  checkboxes?.addAcreditedInvestor?.agreementText ??
                    'No checkbox added',
                )
              : parse(
                  checkboxes?.addPrivateInvestor?.agreementText ??
                    'No checkbox added',
                  {
                    replace: (domNode) => {
                      //change <u> tag to project title
                      //workaround to use kind of handlebars in checkbox text
                      if (domNode.type === 'tag' && domNode.name === 'u') {
                        return <>{project.title}</>
                      }
                    },
                  },
                )
          }
          addInvestor
          onSubmit={handleNewInvestor}
          errorMessage={errorMsg}
        />
      )}

      {editInvestorModal && (
        <InvestorsModal
          isModalOpen={editInvestorModal}
          onModalClose={() => seteditInvestorModal(false)}
          title="Change Investor status"
          // caption="Please choose the state of the private investor."
          caption={
            user?.user_type === 'company_pa'
              ? parse(
                  checkboxes?.addAcreditedInvestor?.agreementText ??
                    'No checkbox added',
                )
              : parse(
                  checkboxes?.addPrivateInvestor?.agreementText ??
                    'No checkbox added',
                  {
                    replace: (domNode) => {
                      //change <u> tag to project title
                      //workaround to use kind of handlebars in checkbox text
                      if (domNode.type === 'tag' && domNode.name === 'u') {
                        return <>{project.title}</>
                      }
                    },
                  },
                )
          }
          isUpdatingInvestor={isUpdatingInvestor}
          editInvestor
          errorMessage={errorMsg}
          value={{
            status: currentInvestor.status,
            notes: currentInvestor.notes || '',
            email: currentInvestor.email,
            name: currentInvestor.name,
          }}
          onSubmit={handleEditInvestor}
        />
      )}
    </Layout>
  )
}

export default AdminPrivateInvestor
