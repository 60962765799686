import axios from 'axios'
import getAuthHeader from 'utils/getAuthHeader'

const baseURL = process.env.REACT_APP_API_URL
const baseURLPublic = process.env.REACT_APP_BASE_URL_PUBLIC

class Project {
  static getProject = async (user) =>
    (
      await axios.post(`${baseURLPublic}/project/public/get`, {
        companyId: user.company.toString(),
      })
    ).data.projects[0]

  static getProjectsByName = async (projectNamesList) =>
    (
      await axios.post(`${baseURLPublic}/project/public/get`, {
        projectNamesList,
      })
    ).data.projects

  static getInvestorRegister = async (project, snapDate = null) => {
    let body = {
      name: project.name,
    }
    if (snapDate) {
      body.snapDate = snapDate
    }
    return (
      await axios.post(`${baseURL}/project/investorRegister`, body, {
        headers: getAuthHeader(),
      })
    ).data
  }

  static getInvestmentList = async () =>
    (
      await axios.post(
        `${baseURL}/investment/list`,
        {},
        { headers: getAuthHeader() },
      )
    ).data.list

  static getPrivateInvestors = async () =>
    (
      await axios.post(
        `${baseURL}/private-investor/list`,
        {},
        { headers: getAuthHeader() },
      )
    ).data.list

  static createPrivateInvestor = async (data) =>
    (
      await axios.post(`${baseURL}/private-investor/create`, data, {
        headers: getAuthHeader(),
      })
    ).data

  static updatePrivateInvestor = async (data) =>
    await axios.post(`${baseURL}/private-investor/update`, data, {
      headers: getAuthHeader(),
    })

  static addNAVPrice = async (data) =>
    await axios.post(`${baseURL}/prices/addNAVPrice`, data, {
      headers: getAuthHeader(),
    })

  static deleteNAVPrice = async (data) =>
    await axios.post(`${baseURL}/prices/deleteNAVPrice`, data, {
      headers: getAuthHeader(),
    })

  static getNAVPrices = async (data) =>
    (
      await axios.post(`${baseURL}/prices/getNAVPrices`, data, {
        headers: getAuthHeader(),
      })
    ).data

  static getUserList = async (user) =>
    (
      await axios.post(
        `${baseURL}/user/list`,
        { company: user.company.toString() },
        { headers: getAuthHeader() },
      )
    ).data.list
}

export default Project
