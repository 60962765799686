import { css } from 'styled-components'

// https://github.com/Semantic-Org/Semantic-UI/blob/master/src/themes/default/globals/site.variables
export const sizes = {
  widescreen: 1920,
  default: 1440,
  large: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 375,
  mobileS: 320,
}

const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16

  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export default media
