import React, { Fragment, useEffect, useState, useContext } from 'react'

import Layout from 'components/AdminDashboard/Layout/Layout'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import {
  Card,
  CardTitle,
  CardHeader,
} from 'components/AdminDashboard/Card/Card.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Text from 'components/Text/Text.styles'
import OverviewItem from 'components/AdminDashboard/OverviewItem/OverviewItem'
import CircleProgress from 'components/AdminDashboard/CircleProgress/CircleProgress'
import BarChartLegend from 'components/BarChartLegend/BarChartLegend'
import InvestmentRatio from 'components/AdminDashboard/InvestmentRatio/InvestmentRatio'
import OverviewChart from 'components/AdminDashboard/OverviewChart/OverviewChart'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'
import { km_ify, CryptoAddress } from '@stokr/components-library'
import { fixDecimals } from 'utils'
import StatusItem from 'components/AdminDashboard/StatusItem/StatusItem'
import Table from 'components/AdminDashboard/Table/Table'
import moment from 'moment'
import SingleProgressBar from 'components/AdminDashboard/SingleProgressBar/SingleProgressBar'
import { RightSlotText } from 'components/AdminDashboard/OverviewItem/OverviewItem.styles'
import CapItem from 'components/AdminDashboard/CapItem/CapItem'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router'
import {
  formatCurrencyValue,
  getCurrencySymbol,
  getProjectCurrencySign,
} from '@stokr/components-library/dist/utils/formatCurrencyValue'
import { Button } from '@stokr/components-library/dist/components/Button/Button.styles'
import NAVModal from 'components/NAVModal/NAVModal'

const tableColumns = [
  {
    key: 'email',
    label: 'Investor email address',
    width: '30%',
  },
  {
    key: 'createdAt',
    label: 'Date',
    width: '30%',
  },
  {
    key: 'tokenAmount',
    label: 'Securities Amount',
    width: '20%',
  },
  {
    key: 'currencyAmount',
    label: 'Currency amount',
    width: '20%',
  },
]

const secondaryMarketTableColumns = [
  {
    key: 'recipientEmail',
    label: 'Receiver email address',
    width: '30%',
  },
  {
    key: 'createdAt',
    label: 'Date',
    width: '20%',
  },
  {
    key: 'amount',
    label: 'Securities Amount',
    width: '20%',
  },
  {
    key: 'senderEmail',
    label: 'Sender email address',
    width: '30%',
  },
]

const redemptionTableColumns = [
  {
    key: 'email',
    label: 'Investor email address',
    width: '30%',
  },
  {
    key: 'createdAt',
    label: 'Redemption request Date',
    width: '20%',
  },
  {
    key: 'tokenAmount',
    label: 'Securities Amount',
    width: '15%',
  },
  {
    key: 'value',
    label: 'Redemption value',
    width: '20%',
  },
  {
    key: 'status',
    label: 'Redemption Status',
    width: '15%',
  },
]

const AdminOverview = () => {
  const projectContext = useContext(ProjectContext)

  const [_tokenSymbol, setTokenSymbol] = useState()
  const [_tokenPrice, setTokenPrice] = useState()
  const [_daysLeft, setDaysLeft] = useState('-')
  const [_daysLeftPercentage, setDaysLeftPercentage] = useState()
  const [_tokensSoldOverTime, setTokensSoldOverTime] = useState()
  const [_investmentRatio, setInvestmentRatio] = useState()
  const [_privateSale, setPrivateSale] = useState(0)
  const [_publicSale, setPublicSale] = useState(0)
  const [_hardCap, setHardCap] = useState(0)
  const [_privateSalePercentage, setPrivateSalePercentage] = useState()
  const [_publicSalePercentage, setPublicSalePercentage] = useState()
  const [_finalizedTransactions, setFinalizedTransactions] = useState([])
  const [_pendingTransactions, setPendingTransactions] = useState([])
  const [investmentsList, setInvestmentsList] = useState([])
  const [_capitalRaisedProgressBar, setCapitalRaisedProgressBar] = useState(0)
  const [_companyType, setCompanyType] = useState()
  const [_project, setProject] = useState({})

  //MODAL State
  const [isNAVModalOpen, setIsNAVModalOpen] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const redirectUrlCookie = Cookies.get('STOKR_REDIRECT_URL')
    if (redirectUrlCookie) {
      Cookies.remove('STOKR_REDIRECT_URL')
      navigate(redirectUrlCookie)
    }
  }, [])

  useEffect(() => {
    if (projectContext) {
      const {
        project,
        tokenSymbol,
        tokenPrice,
        daysLeft,
        daysLeftPercentage,
        privateSale,
        hardCap,
        publicSale,
        privateSalePercentage,
        publicSalePercentage,
        investmentRatio,
        tokensSoldOverTime,
        investmentsList,
      } = projectContext

      project && setCompanyType(project.type)
      project && setProject(project)

      setTokenSymbol(tokenSymbol)
      setInvestmentsList(investmentsList)
      setTokenPrice(tokenPrice)
      setDaysLeft(daysLeft)
      setDaysLeftPercentage(daysLeftPercentage)
      setTokensSoldOverTime(tokensSoldOverTime)
      setInvestmentRatio(groupInvestmentRatio(investmentRatio))
      setPrivateSale(privateSale)
      setPublicSale(publicSale)
      setHardCap(hardCap)
      setPrivateSalePercentage(privateSalePercentage)
      setPublicSalePercentage(publicSalePercentage)
    }
  }, [projectContext])

  useEffect(() => {
    _tokensSoldOverTime &&
      _tokensSoldOverTime.weeks.reverse() &&
      //_tokensSoldOverTime.months.reverse() &&
      _tokensSoldOverTime.years.reverse()
  }, [_tokensSoldOverTime])

  useEffect(() => {
    if (_project) {
      const totalHardCap = Number(_project.greenCap) + Number(_project.whiteCap)
      const capitalRaisedProgressBar = (_project.totalSold / totalHardCap) * 100
      setCapitalRaisedProgressBar(capitalRaisedProgressBar)
    }
  }, [_project])

  useEffect(() => {
    if (investmentsList.length > 0) {
      const finalized = []
      const pending = []

      investmentsList.forEach((investment) => {
        const {
          currencyType,
          fulfilled,
          createdAt,
          tokenAmount,
          currencyAmount,
          secondaryCurrency,
          secondaryCurrencyinEURorUSD,
          email = 'Not available',
        } = investment

        const fixedCurrencyAmount = formatCurrencyValue(
          currencyType,
          secondaryCurrency
            ? currencyAmount / secondaryCurrencyinEURorUSD
            : currencyAmount,
        )

        const data = {
          createdAt: moment(createdAt)
            .format('DD MMM YYYY - hh:mm')
            .toUpperCase(),
          tokenAmount: fixDecimals(tokenAmount, 2),
          currencyType,
          currencyAmount: fixedCurrencyAmount,
          email,
        }

        if (currencyType === 'ether' || fulfilled) {
          finalized.push(data)
        } else {
          pending.push(data)
        }
      })

      setFinalizedTransactions(finalized.reverse())
      setPendingTransactions(pending.reverse())
    }
  }, [investmentsList])

  return (
    <Layout currentPage="overview">
      <Row mainDashboard>
        <Column>
          <ContentBox>
            <Row>
              <Column partLg={11}>
                <Row>
                  <Column>
                    <Card>
                      <Row spaceBetween middle>
                        <Text>
                          <h3>Overview</h3>
                        </Text>
                      </Row>
                      <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                        <Row>
                          <Column part={8} partXl={4}>
                            <OverviewItem
                              title="Investors"
                              content={_project.investmentCount}
                            />
                          </Column>

                          <Column part={8} partXl={4}>
                            <OverviewItem
                              title="Days left"
                              content={isNaN(_daysLeft) ? `-` : `${_daysLeft}`}
                              right={
                                <CircleProgress
                                  id="cp0"
                                  progress={_daysLeftPercentage}
                                />
                              }
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column partXl={16}>
                            <OverviewItem
                              title="total CAPITAL RAISED"
                              right={
                                <RightSlotText>
                                  {getProjectCurrencySign(
                                    _project.tokenCurrency,
                                  )}
                                  {parseInt(_project.totalSold).toLocaleString(
                                    'en',
                                  )}
                                </RightSlotText>
                              }
                              rightAlign
                              bottom={
                                <SingleProgressBar
                                  colorTheme="blue"
                                  progress={_capitalRaisedProgressBar}
                                  label={`Hard cap 
                                  ${getProjectCurrencySign(
                                    _project.tokenCurrency,
                                  )}
                                  
                                  ${km_ify(_hardCap)}`}
                                />
                              }
                            />
                          </Column>
                        </Row>
                      </ComponentWrapper>
                    </Card>
                  </Column>

                  <Column partMd={8} isTokenSales>
                    <Card>
                      <CapItem
                        isTokenSales
                        title=""
                        text={
                          _companyType && _companyType === 'company'
                            ? 'Public sale'
                            : 'Current Tranche'
                        }
                        overviewItemProps={{
                          title: 'Capital raised',
                          right: (
                            <RightSlotText>
                              {getProjectCurrencySign(_project.tokenCurrency)}

                              {isNaN(_publicSale)
                                ? '-'
                                : parseInt(_publicSale).toLocaleString('en')}
                            </RightSlotText>
                          ),
                          bottom: (
                            <SingleProgressBar
                              colorTheme="green"
                              progress={_publicSalePercentage}
                              label=""
                            />
                          ),
                          rightAlign: true,
                        }}
                      />
                    </Card>
                  </Column>
                  <Column partMd={8} isTokenSales>
                    <Card>
                      <CapItem
                        isTokenSales
                        title=""
                        text={
                          _companyType && _companyType === 'company'
                            ? 'Private sale'
                            : 'Previous Tranches'
                        }
                        overviewItemProps={{
                          title: 'capital raised',
                          right: (
                            <RightSlotText>
                              {getProjectCurrencySign(_project.tokenCurrency)}

                              {isNaN(_privateSale)
                                ? '-'
                                : parseInt(_privateSale).toLocaleString('en')}
                            </RightSlotText>
                          ),
                          bottom: (
                            <SingleProgressBar
                              colorTheme="red"
                              progress={_privateSalePercentage}
                              label=""
                            />
                          ),
                          rightAlign: true,
                        }}
                      />
                    </Card>
                  </Column>

                  <Column>
                    {_tokensSoldOverTime && (
                      <OverviewChart
                        title="Investment history"
                        barChartData={_tokensSoldOverTime}
                        id="tokenSalesHistory"
                        colorTheme="red"
                        tokenSymbol={_tokenSymbol}
                        tokenPrice={_tokenPrice}
                        barChartLegend={
                          <BarChartLegend
                            id="tokenSalesHistoryBarChartLegend"
                            colorTheme="red"
                            label="account balance"
                          />
                        }
                      />
                    )}
                  </Column>
                </Row>
              </Column>
              <Column partLg={5}>
                <Row column grow={1}>
                  <Column>
                    <Card lessPadding grow={1}>
                      <CardTitle>Offering Status Block</CardTitle>
                      <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                        <StatusItem
                          message="Offering started"
                          tooltip=""
                          isDone={_project.isOpen || _project.hasClosed}
                        />

                        <StatusItem
                          message="Min amount reached"
                          tooltip=""
                          isDone={_project.totalSold > _project.totalSoftCap}
                        />
                        <StatusItem
                          message="Offering closed"
                          tooltip=""
                          isDone={_project.hasClosed}
                        />
                        <StatusItem
                          message="Tokens transferable"
                          tooltip=""
                          isDone={
                            new Date(_project.transferableTime) < new Date()
                          }
                        />
                      </ComponentWrapper>
                    </Card>
                  </Column>

                  <Column>
                    <Card lessPadding grow={1}>
                      <ComponentWrapper
                        noPaddingTop
                        noPaddingHorizontal
                        borderBottom
                      >
                        <CardTitle>Investment ratio</CardTitle>
                      </ComponentWrapper>

                      <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                        {_investmentRatio &&
                          Object.entries(_investmentRatio).map(
                            ([currency, percentage]) => (
                              <InvestmentRatio
                                currency={currency}
                                percent={percentage}
                                colorTheme="blue"
                              />
                            ),
                          )}
                      </ComponentWrapper>
                    </Card>
                  </Column>
                </Row>
              </Column>

              {/* INVESTMENT EXECUTED */}
              <Column>
                <Card>
                  <CardHeader>
                    <Text withMarginBottom withMarginRight>
                      <h3>Investments executed</h3>
                    </Text>
                  </CardHeader>

                  <ComponentWrapper
                    noPaddingTop
                    noPaddingBottom
                    noPaddingHorizontal
                  >
                    <Table
                      maxHeight={400}
                      columns={tableColumns}
                      data={_finalizedTransactions.map((row) => {
                        return {
                          ...row,
                        }
                      })}
                    />
                  </ComponentWrapper>
                </Card>
              </Column>

              {/* Investments Pending */}
              <Column>
                <Card>
                  <CardHeader>
                    <Text withMarginBottom withMarginRight>
                      <h3>Investments Pending</h3>
                    </Text>
                  </CardHeader>

                  <ComponentWrapper
                    noPaddingTop
                    noPaddingBottom
                    noPaddingHorizontal
                  >
                    <Table
                      maxHeight={400}
                      columns={tableColumns}
                      data={_pendingTransactions.map((row) => {
                        return {
                          ...row,
                        }
                      })}
                    />
                  </ComponentWrapper>
                </Card>
              </Column>

              {/* SECONDARY MARKET TRANSACTIONS */}
              <Column>
                <Card>
                  <CardHeader>
                    <Text withMarginBottom withMarginRight>
                      <h3>SECONDARY MARKET TRANSACTIONS</h3>
                    </Text>
                  </CardHeader>

                  {projectContext.isLoadingInvestorRegister ? (
                    <>
                      <ComponentWrapper center fluidcenter>
                        <Text blockchainLoadingMsg>
                          <p>
                            A few seconds left. We are still reading the data
                            out of the blockchain...
                          </p>
                        </Text>
                      </ComponentWrapper>
                    </>
                  ) : projectContext.secondaryMarketTx.length < 1 ? (
                    <ComponentWrapper center fluidcenter>
                      <Text blockchainLoadingMsg>
                        <p>There are no transactions yet</p>
                      </Text>
                    </ComponentWrapper>
                  ) : (
                    <ComponentWrapper
                      noPaddingTop
                      noPaddingBottom
                      noPaddingHorizontal
                    >
                      <Table
                        maxHeight={400}
                        columns={secondaryMarketTableColumns}
                        data={projectContext.secondaryMarketTx.map((row) => {
                          return {
                            ...row,
                            senderEmail: row.senderEmail?.includes('@') ? (
                              row.senderEmail
                            ) : (
                              <CryptoAddress
                                noHead
                                fontSize={14}
                                data={{
                                  value: row.senderEmail,
                                  tooltip: true,
                                  shortAddress: true,
                                }}
                              />
                            ),
                            recipientEmail: row.recipientEmail?.includes(
                              '@',
                            ) ? (
                              row.recipientEmail
                            ) : (
                              <CryptoAddress
                                noHead
                                fontSize={14}
                                data={{
                                  value: row.recipientEmail,
                                  tooltip: true,
                                  shortAddress: true,
                                }}
                              />
                            ),
                          }
                        })}
                      />
                    </ComponentWrapper>
                  )}
                </Card>
              </Column>

              {/* REDEMPTION TRANSACTIONS */}
              <Column>
                <Card>
                  <CardHeader>
                    <Text withMarginBottom withMarginRight>
                      <h3>Redemptions</h3>
                    </Text>
                  </CardHeader>

                  {projectContext.isLoading ? (
                    <>
                      <ComponentWrapper center fluidcenter>
                        <Text blockchainLoadingMsg>
                          <p>
                            A few seconds left. We are still loading the data...
                          </p>
                        </Text>
                      </ComponentWrapper>
                    </>
                  ) : projectContext.redemptionTx.length < 1 ? (
                    <ComponentWrapper center fluidcenter>
                      <Text blockchainLoadingMsg>
                        <p>There are no transactions yet</p>
                      </Text>
                    </ComponentWrapper>
                  ) : (
                    <ComponentWrapper
                      noPaddingTop
                      noPaddingBottom
                      noPaddingHorizontal
                    >
                      <Table
                        maxHeight={400}
                        columns={redemptionTableColumns}
                        data={projectContext.redemptionTx.map((row) => {
                          return {
                            ...row,
                            createdAt: moment(row.createdAt)
                              .format('DD MMM YYYY - hh:mm')
                              .toUpperCase(),
                            value: `${formatCurrencyValue(
                              row.currencyType,
                              row.currencyAmount,
                            )}`,
                          }
                        })}
                      />
                    </ComponentWrapper>
                  )}
                </Card>
              </Column>
            </Row>
          </ContentBox>
        </Column>
      </Row>
    </Layout>
  )
}

const groupInvestmentRatio = (investmentRatio) => {
  if (investmentRatio) {
    //go over entries and group together by the symbol name
    return Object.entries(investmentRatio).reduce((list, investment) => {
      let symbol = getCurrencySymbol(investment[0])

      if (list[symbol]) {
        list[symbol] += Number(investment[1])
      } else {
        list[symbol] = Number(investment[1])
      }
      return list
    }, {})
  }
  return {}
}

export default AdminOverview
