import React from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalInner } from 'components/Modal/Modal'
import Text from 'components/Text/Text.styles'
import TextLink from 'components/TextLink/TextLink.styles'
import { Row, Column } from 'components/Grid/Grid.styles'
import { ContainerWithLine, RedBar } from './InvestorsModal.styles'
import Button from 'components/Button/Button.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Input from 'components/Input/Input'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { FormError, FormField } from 'components/Form/Form.styles'
import Form from 'components/Form/Form'
import Select from 'components/Input/Select'
import TextEditor from 'components/AdminDashboard/TextEditor/TextEditor'
import { InvestorStatus } from 'pages/admin-dashboard/private-investor'

const generateOptions = (list) => {
  return (
    list &&
    list.map((item, index) => {
      return {
        key: item,
        label: item,
        value: item,
      }
    })
  )
}

const InvestorsModal = ({
  isModalOpen,
  onModalClose,
  title,
  caption,
  addInvestor,
  value,
  onSubmit,
  editInvestor,
  errorMessage,
  isUpdatingInvestor,
}) => {
  const getValidationSchema = () => {
    if (addInvestor) {
      return Yup.object().shape({
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        name: Yup.string().required(`Oops, this can't be blank`),
      })
    } else if (editInvestor) {
      return Yup.object().shape({
        notes: Yup.string().test('len', 'Cannot exceed 140 characters', (val) =>
          val ? val.length !== 140 : true,
        ),
      })
    }
  }
  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} closeIsFullHeight>
      <Row>
        <Column relative part={11}>
          <ModalInner isConfirmModal>
            <ContainerWithLine>
              <Text withBorder>
                <h3>{title}</h3>
              </Text>

              <Formik
                initialValues={{
                  email: value.email,
                  status: value.status,
                  notes: value.notes,
                  name: value.name,
                }}
                validationSchema={getValidationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  const onChangeWithTouch = (e) => {
                    const field = e.target
                    setFieldValue(field.name, field.value, false)
                    setFieldTouched(field.name)
                  }
                  const onSelectChange = (select) => {
                    setFieldValue(select.name, select.value)
                  }

                  return (
                    <Form onSubmit={onSubmit}>
                      <FormField>
                        <br />
                        {addInvestor && (
                          <>
                            <Input
                              id="email_investor"
                              name="email"
                              type="email"
                              label="add email address"
                              value={values.email}
                              onChange={onChangeWithTouch}
                              onBlur={handleBlur}
                              error={!!errors.email}
                              touched={!!touched.email}
                            />
                            <FormError show={errors.email && touched.email}>
                              {errors.email}
                            </FormError>
                            <br />
                            <Input
                              id="name_investor"
                              name="name"
                              type="name"
                              label="add name"
                              value={values.name}
                              onChange={onChangeWithTouch}
                              onBlur={handleBlur}
                              error={!!errors.name}
                              touched={!!touched.name}
                            />
                            <FormError show={errors.name && touched.name}>
                              {errors.name}
                            </FormError>
                            <br />
                            <Text.Caption>{caption}</Text.Caption>
                          </>
                        )}
                        {editInvestor && (
                          <>
                            <Select
                              id="status_investor"
                              name="status"
                              value={values.status}
                              options={generateOptions(
                                Object.values(InvestorStatus).filter(
                                  (status) =>
                                    status !== InvestorStatus.APPROVED,
                                ),
                              )}
                              label="status"
                              onChange={onSelectChange}
                              // onBlur={handleBlur}
                            />
                            <div style={{ minHeight: 65, margin: '1.2em 0' }}>
                              {values.status === InvestorStatus.ADMITTED && (
                                <Text.Caption>{caption}</Text.Caption>
                              )}
                            </div>
                            <TextEditor
                              id="notes_investor"
                              name="notes"
                              maxHeight={35}
                              minHeight={30}
                              maxLength={140}
                              placeholder="Leave notes"
                              // info="Leave notes for investor"
                              emojiPicker={false}
                              noFooter
                              smallPadding
                              value={values.notes}
                              onChange={onChangeWithTouch}
                              onBlur={handleBlur}
                            />
                            <br />
                            <br />
                            <FormError show={errors.notes}>
                              {errors.notes}
                            </FormError>
                          </>
                        )}
                      </FormField>
                      <FormError show={errorMessage}>{errorMessage}</FormError>
                      <br />

                      <ComponentWrapper noPaddingBottom>
                        <Button type="submit" disabled={isUpdatingInvestor}>
                          {isUpdatingInvestor ? 'Processing..' : 'Submit'}
                        </Button>
                      </ComponentWrapper>
                    </Form>
                  )
                }}
              </Formik>
            </ContainerWithLine>
          </ModalInner>
        </Column>
      </Row>
      <RedBar />
    </Modal>
  )
}

InvestorsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  caption: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.shape({
    email: PropTypes.string,
    status: PropTypes.string,
    notes: PropTypes.string,
  }),
  addInvestor: PropTypes.bool,
  editInvestor: PropTypes.bool,
  errorMessage: PropTypes.string,
}

InvestorsModal.defaultProps = {
  caption: '',
  title: '',
  value: {
    email: '',
    status: '',
    notes: '',
    name: '',
  },
  errorMessage: '',
}

export default InvestorsModal
