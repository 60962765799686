import styled, { css } from 'styled-components/macro'

export const DateInput = styled.input`
  font-family: 'Open sans';
  display: block;
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 13px 0;
  padding-bottom: 7px;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  border: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid ${(props) => props.theme.cGrey};
  color: ${(props) => props.theme.cBlack};
  background-color: transparent;
  transition: border-color 0.2s;

  &:focus,
  &:hover {
    box-shadow: none;
    border-top-color: transparent;
    border-bottom-color: ${(props) => props.theme.cPrimary};
  }

  &::placeholder {
    color: transparent;
    opacity: 0;
  }

  ${(props) =>
    props.dashboard &&
    css`
      border: 0;
      padding: 0;
      height: 22px;
      font-family: 'Open Sans';
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.53px;
      text-transform: normal;
      color: ${props.theme.cBlack};

      &::placeholder {
        color: ${props.theme.cGrey2};
        opacity: 1;
      }
    `}
`

export const DatePickerOverlay = styled.div`
  &&& > .rdp {
    display: block;
    position: relative;
    width: 100%;
    position: absolute;
    z-index: 2;
    background-color: white;
    margin: 0;
    box-shadow: -1px 1px 1px 1px #e1e1e1;
    padding: 5px;
    border-radius: 2px;

    --rdp-cell-size: 40px;
    --rdp-caption-font-size: 18px;
    --rdp-accent-color: #0000ff;
    /* --rdp-background-color: #e7edff; */
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: none; /*2px solid var(--rdp-accent-color);*/
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);
    //margin: 1em;

    min-width: fit-content;
  }

  &&& > .DayPickerInput-OverlayWrapper {
    z-index: 100;
  }
`

export default DatePickerOverlay
